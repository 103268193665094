// DiscountForm.js
import React, { useEffect, useState } from "react";
import * as helpers from "../../utils/utils.js";
import "./discountForm.css";

const DiscountForm = ({
  appliedDiscounts,
  setAppliedDiscounts,
  validateDiscount,
  totalDiscountPrice,
  setTotalDiscountPrice,
  priceBeforeDiscount,
  discounts,
}) => {
  const [discountCode, setDiscountCode] = useState("");
  const [validationMessage, setValidationMessage] = useState(null);

  useEffect(() => {
    setValidationMessage(null);
  }, [discountCode]);

  async function handleFormSubmit(e) {
    e.preventDefault();
    // check discount codes with request

    if (discountCode === "") {
      setValidationMessage("Enter Discount Code");
      return;
    }

    console.log(discounts);

    if (discountCode in appliedDiscounts) {
      setValidationMessage("Code Already Used");
    } else {
      const discountAmount = validateDiscount(priceBeforeDiscount, discountCode);
      if (discountAmount === 0) {
        setValidationMessage("Code Not Valid");
        return;
      }
      setTotalDiscountPrice((prevPrice) => prevPrice - discountAmount);
      setAppliedDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [discountCode]: discountAmount,
      }));

      setDiscountCode("")
      return;
    }
    
  }

  function handleInputChange(event) {
    setDiscountCode(event.target.value);
  }

  return (
    <div className="discount-container">
      {/* <div className='instructions'>Enter Discount or Promo Code Below.</div> */}
      <form onSubmit={(e) => handleFormSubmit(e)}>
        <div className="discount-form">
          <div className="discount-group">
            <input
              placeholder="Enter Discount Code"
              type="text"
              name="discountCode"
              value={discountCode}
              onChange={handleInputChange}
              className="form-control"
            />

            {validationMessage && (
              <div className="invalid">{validationMessage}</div>
            )}
          </div>
          <button className="btn discount-sbmt-btn" type="submit">
            Apply
          </button>
        </div>
      </form>
    </div>
  );
};

export default DiscountForm;
