// DiscountForm.js
import React, { useCallback, useEffect, useState } from "react";
import DiscountForm from "../discountForm/discountForm.js";
import DisplayHeading from "../displayHeading/displayHeading.js";
import * as helpers from "../../utils/utils.js";
import "./priceDisplay.css";

const PriceDisplay = ({
  hideDiscountForm,
  units,
  discounts,
  setCosts,
  selectedSlots,
  setStep,
  prices,
  appliedDiscounts,
  setAppliedDiscounts,
}) => {
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalDiscountPrice, setTotalDiscountPrice] = useState(0);

  // useEffect(() => {
  //   console.log("totalDiscountedPrice", totalDiscountPrice);
  // }, [totalDiscountPrice]);

  // validates a discount and returns amount of money that is saved because of it (0 if not valid)
  const validateDiscount = useCallback(
    (currentPrice, discountId) => {
      if (!discounts[discountId]) {
        return 0;
      }

      const criteria = discounts[discountId].criteria;
      let isValid = false;

      if (criteria.type === "slot") {
        const numSlotsBooked = selectedSlots.length;
        isValid = numSlotsBooked >= criteria.min;
      } else {
        const numBooked = selectedSlots.filter(
          (slot) => prices[slot.priceId].type === criteria.type,
        ).length;
        isValid = numBooked >= criteria.min;
      }

      if (!isValid) {
        return 0;
      }

      const effect = discounts[discountId].effect;

      if (effect.type === "percent") {
        return currentPrice * effect.amount;
      }

      if (effect.type === "money") {
        return effect.amount;
      }

      return 0;
    },
    [discounts, selectedSlots, prices],
  );

  useEffect(() => {
    let totalDiscount = 0;
    Object.entries(appliedDiscounts).forEach(([discountId, amount]) => {
      totalDiscount += amount;
    });

    setCosts({
      deposit: {
        amount: totalDeposit,
        paid: 0,
      },
      remaining: {
        amount: totalPrice - totalDiscount - totalDeposit,
        paid: 0,
      },
    });

    setTotalDiscount(totalDiscount);
  }, [setCosts, appliedDiscounts, totalDeposit, totalPrice]);

  useEffect(() => {
    let priceBeforeDiscount = 0;
    let deposit = 0;
    let priceAfterDiscount = 0;

    for (let slot of selectedSlots) {
      const slotPrice = prices[slot.priceId];
      priceBeforeDiscount += slotPrice.price;
      deposit += slotPrice.deposit;
    }

    // auto discounts
    const autoDiscounts = {};

    Object.keys(discounts).forEach((discountId) => {
      const discountInfo = discounts[discountId];
      if (discountInfo.type === "automatic") {
        autoDiscounts[discountId] = discountInfo;
      }
    });

    setAppliedDiscounts((prevState) => {
      const updatedAppliedDiscounts = { ...prevState };
      for (let discountId in discounts) {
        const discountInfo = discounts[discountId];
        const payout = validateDiscount(priceBeforeDiscount, discountId);

        if (discountInfo.type === "automatic" && payout > 0) {
          priceAfterDiscount -= payout;
          updatedAppliedDiscounts[discountId] = payout;
        
        } else if (updatedAppliedDiscounts[discountId]) {

          if (payout > 0) {
            priceAfterDiscount += updatedAppliedDiscounts[discountId];
            priceAfterDiscount -= payout;
            updatedAppliedDiscounts[discountId] = payout;
          } else {
            priceAfterDiscount += updatedAppliedDiscounts[discountId];
            delete updatedAppliedDiscounts[discountId];
          }

        }
      }

      return updatedAppliedDiscounts;
    });

    setTotalPrice(priceBeforeDiscount);
    setTotalDiscountPrice(priceAfterDiscount);
    setTotalDeposit(deposit);
  }, [
    selectedSlots,
    prices,
    setAppliedDiscounts,
    discounts,
    setTotalDeposit,
    setTotalPrice,
    validateDiscount,
  ]);

  return (
    <div className="price-display-container main-border">
      <DisplayHeading setStep={setStep} step={0} title={"Cart"} />

      {(appliedDiscounts.length > 0 || selectedSlots.length > 0) && (
        <div className="container cost-details">
          <table className="table selections-table">
            <tbody>
              {selectedSlots
                .sort((a, b) => a.date - b.date)
                .map(({ unitId, priceId, date }) => {
                  const priceInfo = prices[priceId];
                  const unitInfo = units[unitId];
                  const dateInt = parseInt(date);
                  const { hours, minutes } = helpers.minutesToHours(
                    priceInfo.duration,
                  );
                  const formattedDate = new Date(dateInt).toLocaleDateString(
                    "en-US",
                    {
                      weekday: "long",
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                      timeZone: "UTC",
                    },
                  );

                  const startTime = `${helpers.formatTime(priceInfo.startTime.hours, priceInfo.startTime.minutes)} EST`;
                  const endTime = `${helpers.formatTime(hours + priceInfo.startTime.hours, minutes + priceInfo.startTime.minutes)} EST`;

                  return (
                    <tr key={date + "-" + unitId}>
                      <td className="unit-description">
                        <img
                          className="price-image"
                          src={unitInfo.picture}
                          alt={unitInfo.name}
                          width="fit"
                        />
                        <div>
                          <span className="bold-text">{unitInfo.name}</span> <br /> {formattedDate} <br /> {startTime + " - " + endTime}
                        </div>
                      </td>
                      <td className="price-item">${priceInfo.price}</td>
                    </tr>
                  );
                })}

              {Object.entries(appliedDiscounts).map(([discountId, amount]) => {
                const discountInfo = discounts[discountId];
                return (
                  <tr key={discountId}>
                    <td>PROMO: {discountInfo.id}</td>
                    <td className="price-item">-${amount}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {!hideDiscountForm && <DiscountForm
            appliedDiscounts={appliedDiscounts}
            setAppliedDiscounts={setAppliedDiscounts}
            validateDiscount={validateDiscount}
            totalDiscountPrice={totalDiscountPrice}
            setTotalDiscountPrice={setTotalDiscountPrice}
            priceBeforeDiscount={totalPrice}
            discounts={discounts}
          />}

          <table className="table price-table">
            <tbody>
              <tr className="cost-display-contianer">
                <td>
                  <div>Total Cost</div>
                  <div className="cost-note">*Additional Fees Apply: Fuel</div>
                </td>
                <td className="price-item" id="total-price">${totalPrice - totalDiscount}</td>
              </tr>
              <tr className="deposit-display-contianer">
                <td className="indent">Amount Due on Arrival</td>
                <td className="price-item">
                  ${totalPrice - totalDiscount - totalDeposit}
                </td>
              </tr>
              <tr className="deposit-display-contianer">
                <td className="indent">Deposit Due Today</td>
                <td className="price-item">${totalDeposit}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default PriceDisplay;
