// App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import MaintenanceMessage from "./components/maintenanceMessage/maintenanceMessage";
// import Navigation from './pages/Navigation';
// import Clients from './pages/clients';

import "./App.css";
import Admin from "./pages/Admin";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/">
          <Route
            path="/torchrentals"
            element={<Home client_id={"torchrentals"} admin={false} />}
          />
          {/* <Route
            path="/torchrentalsADM"
            element={<Home client_id={1} admin={true} />}
          /> */}
          <Route
            path="/boattorch"
            element={<Home client_id={"boattorch"} admin={false} />}
          />
          {/* <Route
            path="/boattorchADM"
            element={<Home client_id={"boattorch"} admin={} />}
          /> */}
          {/* <Route path="/admin" element={<Admin />} /> */}
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
