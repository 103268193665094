import React, { useEffect, useState, useRef } from "react";
import DiscountForm from "../discountForm/discountForm.js";
import * as helpers from "../../utils/utils.js";
import "./unitPaymentForm.css";
import {
  ApplePay,
  CreditCard,
  GooglePay,
  PaymentForm,
} from "react-square-web-payments-sdk";

// tile that shows unit information
function UnitPaymentForm({
  setCosts,
  deposit,
  setStep,
  squareCredentials,
  client_id,
  discounts,
  setDiscounts,
  selectedSlots,
  terms
}) {
  const cardRef = useRef(null);
  const termsRef = useRef(null);
  const [payDisabled, setPayDisabled] = useState(true);
  const [jiggleTerms, setJiggleTerms] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: cardRef.current.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const createPayment = async (token, amount) => {
    const slotIds = selectedSlots.map(slot => slot.id);
    const slotStatus = await helpers.post(
      `/clients/${client_id}/date-slots/check?status=available`,
      slotIds,
    );

    if (!slotStatus.confirmed === "true") return;

    setStep(5);

    const data = {
      token: token.token,
      amount: amount,
    };

    const result = await helpers.post(
      `/clients/${client_id}/make-payment`,
      data,
    );

    if (result?.payment?.status === "COMPLETED") {
      const tempPayment = {
        status: "COMPLETED",
        receipt_number: result.payment?.receiptNumber,
        payment_date:
          result.payment?.cardDetails?.cardPaymentTimeline?.capturedAt,
        last_4: result.payment?.cardDetails?.card?.last4,
      };

      setCosts((prevState) => {
        const updatedCosts = { ...prevState };
        updatedCosts.deposit.paid = amount / 100;
        updatedCosts.deposit.payment = { type: "card", ...tempPayment };

        return updatedCosts;
      });
    }
  };

  return (
    <div ref={cardRef} className="payment-container main-border" >
      <div className="payment-heading">
        <p id="payment-title">Payment</p>

        <div className="instructions">
          Please enter your payment information below. <br /> Fuel cost will be charged
          separately.
        </div>

        <div className="deposit">
          <span>Deposit Due Today: ${deposit}</span>
        </div>

        <div className={`terms-box ${jiggleTerms && "shake"}`}>
          <input
            ref={termsRef}
            type="checkbox"
            id="terms"
            checked={!payDisabled}
            onChange={() => {
              setPayDisabled((state) => !state);
              // cardRef.current.focus();
            }}
          />
          <label htmlFor="terms" id="terms-checkbox">
            I agree to the{" "}
            <a href={terms} target="_blank" rel="noreferrer noopener" className="">
              Terms and Conditions
            </a><span className="error-text">*</span>
          </label>
        </div>
      </div>


      
      

      <div className="payment-form">
        {payDisabled && <div className="disable-overlay" onClick={(e) => {
          
          e.stopPropagation();
          setJiggleTerms(true);
          setTimeout(() => {
            setJiggleTerms(false);
          }, "500");


        }}></div>}
        <PaymentForm
          applicationId={squareCredentials.app_id}
          cardTokenizeResponseReceived={(token, verifiedBuyer) => {
            if (payDisabled) return;
            setPayDisabled(true);
            createPayment(token, deposit * 100);
          }}
          locationId={squareCredentials.location_id}
          createPaymentRequest={() => ({
            countryCode: "US",
            currencyCode: "USD",
            total: {
              amount: String(deposit),
              label: "Deposit",
            },
          })}
        >
          {/* <GooglePay className="google-pay"/> */}
          {<CreditCard focus={!payDisabled}/>}
          {/* <ApplePay /> */}
        </PaymentForm>
      </div>
    </div>
  );
}

export default UnitPaymentForm;
