import React, { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import "./bookingCalendar.css";
import * as helpers from "../../utils/utils.js";

function BookingCalendar({ client_id, selectedDates, setSelectedDates, availableSlots, setAvailableSlots }) {
  const yesterday = new Date().setUTCHours(0, 0, 0, 0) - 86400000;
  const legendItems = ["unavailable", "available", "selected"];
  const cutoffTime = new Date().setUTCHours(22, 0, 0, 0); // 6pm EST cutoff for booking
  // const [selectedDates, setSelectedDates] = useState([]); // list of selected dates
  // const [availableUnits, setAvailableUnits] = useState([]); // list of avaibable units w/date
  const [firstVisibleDay, setFirstVisibleDay] = useState(yesterday); //state for active start Date
  const [startDate, setStartDate] = useState(new Date()); 

  // updates availability when startDate or client changes
  useEffect(() => {
    const endDate = firstVisibleDay + 86400000 * 43;
    const status = "available";

    helpers
      .get(
        `/clients/${client_id}/date-slots?startDate=${firstVisibleDay}&endDate=${endDate}&status=${status}`,
      )
      .then((result) => {
        setAvailableSlots((prevSlots) => {
          const selectedDaySlots = prevSlots.filter((slot) => (
            selectedDates.includes(slot.date) &&
            !result.some((resultSlot) => resultSlot.id === slot.id)
          ));

          return [...result, ...selectedDaySlots];
        });
      })
      .catch((error) => {
        console.log({
          message: "an error has occured fetching availability",
          error: error,
        });
        setAvailableSlots([]);
      });
  }, [firstVisibleDay, client_id, setAvailableSlots, selectedDates]);

  // handles start date change and updates startDate state
  const handleActiveStartDateChange = (activeStartDate) => {
    const firstVisible = new Date(activeStartDate);
    firstVisible.setUTCHours(0, 0, 0, 0);
    firstVisible.setDate(firstVisible.getDate() - firstVisible.getDay() - 1); // Adjust to the previous Sunday
    setFirstVisibleDay(firstVisible.valueOf());
  };

  // handles when a day on the calendar is selected or deselected
  function handleClickDay(value, event) {
    value.setUTCHours(0, 0, 0, 0);
    const clickedDay = value.valueOf();

    let updatedSelectedDates = [...selectedDates];

    // if date is unclicked remove selections
    if (updatedSelectedDates.includes(clickedDay)) {
      updatedSelectedDates = updatedSelectedDates.filter(
        (date) => date !== clickedDay,
      );
    } else {
      updatedSelectedDates.push(clickedDay);
    }

    setSelectedDates(updatedSelectedDates.sort());
  }

  /**
   * determines which classname to add to a tile depending on
   * availability of the date
   * @param {Date} date date of the tile
   * @returns {string} name of the class to be added
   */
  function updateCalenderTiles({ date }) {
    date.setUTCHours(0, 0, 0, 0);
    const tileDate = date.valueOf();

    const dateIsSelected = selectedDates.includes(tileDate);

    const dateHasAvailability = availableSlots.some(
      (slot) => slot.date === tileDate,
    );


    if (dateIsSelected) {
      return "selected";
    } else if (dateHasAvailability) {
      return "available";
    } else {
      return "unavailable";
    }
  }

  function isTileDisabled({ date }) {
    date.setUTCHours(0, 0, 0, 0);
    const tileDate = date.valueOf();
    const todayDate = new Date();

    const tileIsToday =
      date.getUTCDate() === todayDate.getUTCDate() &&
      date.getUTCMonth() === todayDate.getUTCMonth() &&
      date.getUTCFullYear() === todayDate.getUTCFullYear();

    const cutoffHasPassed = tileIsToday && todayDate.valueOf() > cutoffTime; // if its after the cutoff time can't book today
    const tooLateToBook =
      tileDate < todayDate.valueOf() - 86400000 || cutoffHasPassed;

    const dateHasAvailability = availableSlots.some((slot) => {
      return parseInt(slot.date) === tileDate;
    });

    if (!tooLateToBook && dateHasAvailability) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <div className="calendar-container main-border">
      <div className="calendar-instructions">
        <div>
          {/* hidden button for spacing */}
          <button className="btn hidden" disabled={true}>
            Clear
          </button>
        </div>
        <div>
          <p className="section-heading">Select Dates</p>
        </div>
        <div>
          <button
            className="clear-btn btn"
            onClick={() => {
              setSelectedDates([]);
            }}
            disabled={selectedDates.length < 1}
          >
            Clear
          </button>
        </div>
      </div>
      <Calendar
        tileClassName={updateCalenderTiles}
        tileDisabled={isTileDisabled}
        onClickDay={handleClickDay}
        activeStartDate={startDate}
        onActiveStartDateChange={({ value, activeStartDate, action }) => {
          if (action === 'next' || action === 'prev') {
            setStartDate(activeStartDate);
            handleActiveStartDateChange(activeStartDate);
          } 
        }}
        minDetail={"month"}
        calendarType="gregory"
      />
      <div className="calendar-legend">
        {legendItems.map((item) => (
          <div key={item} className="item">
            <div className={"secondary-border color " + item}>a</div>
            <div className="label">{item}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BookingCalendar;
