import React from "react";
import "./renterInfoDisplay.css";
import DisplayHeading from "../displayHeading/displayHeading";

function RenterInfoDisplay({ renterInfo, setStep }) {
  return (
    <div className="info-display-container main-border">
      <DisplayHeading
        setStep={setStep}
        step={1}
        title={"Your Provided Details"}
      />

      <div className="container renter-info">
        <div className="info-row"><span className="field-heading">Full Name: </span> {renterInfo.firstName} {renterInfo.lastName}</div>
        <div className="info-row"><span className="field-heading">Email:  </span>{renterInfo.email}</div>
        <div className="info-row"><span className="field-heading">Phone:  </span>{renterInfo.phone}</div>
        <div className="info-row"><span className="field-heading">Address:  </span>{renterInfo.address} {renterInfo.city}, {renterInfo.state} {renterInfo.zip}</div>
        <div className="info-row"></div>
      </div>
    </div>
  );
}

export default RenterInfoDisplay;
