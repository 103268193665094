import React, { useEffect, useRef, useState } from "react";
import * as helpers from "../../utils/utils.js";
import UnitTile from "../unitTile/unitTile.js";
import "./unitTileList.css";

function UnitTileList({
  setSelectedSlots,
  selectedSlots,
  units,
  selectedDates,
  setSelectedDates,
  availableSlots,
  prices,
}) {
  const [selectMultipleUnitsDates, setSelectMultipleUnitsDates] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({}); // holds the options that are selected for a given tile { date, unitId, priceId }

    // when option selection is changed update selectedSlots pricing
    useEffect(() => {
      setSelectedSlots((prevState) =>
        prevState.map((slot) => {
          const unitDateString = `${slot.date}-${slot.unitId}`;
          const newSlot = availableSlots.find(availableSlot => availableSlot.id === selectedOptions[unitDateString])
          return {
            ...slot,
            priceId: newSlot.priceId || slot.priceId, // Keep old priceId if no selection exists
            id: newSlot.id || slot.id,
          };
        }),
      );
    }, [selectedOptions, setSelectedSlots, availableSlots]);


  const handleOptionChange = (date, unitId, slotId) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [`${date}-${unitId}`]: slotId, // Store priceId for specific unitId and date
    }));
  };

  const handleUnitSelectionChange = (unitId, date) => {
    const isSelected = selectedSlots.some(
      (slot) => slot.unitId === unitId && slot.date === date,
    );

    if (isSelected) {
      setSelectedSlots((previousSlots) =>
        previousSlots.filter(
          (slot) => !(slot.unitId === unitId && slot.date === date),
        ),
      );
    } else {
      const unitDateString = `${date}-${unitId}`;
      const newSlotId = selectedOptions[unitDateString];
      const newSlot = availableSlots.find(slot => slot.id === newSlotId);
      setSelectedSlots((previousSlots) => [
        ...previousSlots, {...newSlot}
      ]);
    }
  };

  const handleDateRemoval = (date) => {
    setSelectedDates((prevState) =>
      prevState.filter((selectedDate) => {
        return selectedDate !== parseInt(date);
      }),
    );
  };

  return (
    <>
      <div className="selectable-units main-border">
        <p className="section-heading">
          {`Selections (${String(selectedSlots?.length) ?? "0"})`}
        </p>
        <div className="unit-selection-instructions"></div>
        {selectedDates.map((selectedDate) => {
          const formattedDate = new Date(
            parseInt(selectedDate),
          ).toLocaleDateString("en-US", {
            weekday: "long",
            month: "short",
            day: "numeric",
            timeZone: "UTC",
          });
          return (
            <div className="date-container secondary-border" key={selectedDate}>
              <div className="date-header">
                <div className="date-options">{formattedDate}</div>

                <div className="btn-container">
                  <button
                    className="btn remove-btn"
                    onClick={() => handleDateRemoval(selectedDate)}
                  >
                    X
                  </button>
                </div>
              </div>
              <div className="tile-container">
                {/* for each client unit that also has an available slot */}
                {Object.keys(units).map((unitId) => {

                  // const hasSlots = availableSlots.some(slot => slot.unitId === unitId);
                  // if (!hasSlots) return null;


                  const openSlots = availableSlots
                    .filter(slot => slot.date === selectedDate && slot.unitId === unitId)
                    .sort((a, b) => prices[b.priceId]?.price - prices[a.priceId]?.price);

                  if (openSlots.length === 0) return null;
                  const unitDateString = `${selectedDate}-${unitId}`;
                  if (!selectedOptions[unitDateString]) {
                    setSelectedOptions((prevState) => {
                      const updatedOptions = {...prevState};
                      updatedOptions[unitDateString] = openSlots[0].id;
                      return updatedOptions;
                    })
                  }

                  const isSelected = selectedSlots?.some(
                    (slot) =>
                      slot?.date === selectedDate && slot?.unitId === unitId,
                  );
                  return (
                    <label
                      key={`${selectedDate}-${unitId}`}
                      className={`unit-tile-container ${isSelected ? "selected" : ""}`}
                    >
                      <div className="checkbox-container">
                        <input
                          type="checkbox"
                          name={"unit-tile-checkbox-" + selectedDate}
                          onChange={() =>
                            handleUnitSelectionChange(unitId, selectedDate)
                          }
                          checked={isSelected}
                        />
                      </div>

                      <UnitTile
                        className="selectable-unit-tile"
                        unit={{ ...units[unitId] }}
                      ></UnitTile>

                      <select
                        className="slot-dropdown"
                        onChange={(e) => handleOptionChange(selectedDate, unitId, e.target.value)}
                        value={selectedOptions[`${selectedDate}-${unitId}`]}
                      >
                        {openSlots.map(
                          (slot) => {
                            return (
                              <option
                                value={slot.id}
                                key={`${selectedDate}-${unitId}-${slot.id}`}
                              >
                                {prices[slot.priceId]?.name} $
                                {prices[slot.priceId].price}
                              </option>
                            );
                          },
                        )}
                      </select>


                      {/* <div
                        className="slot-list"
                      >
                        {selectedDateUnits[selectedDate][unitId].map(
                          (priceId, index) => {
                            return (
                              <div className={`slot-list-item ${selectedOptions[`${selectedDate}-${unitId}`] === priceId && "selected"}`}
                                onClick={(e) => {
                                  e.stopPropagation(); 
                                  handleOptionChange(selectedDate, unitId, priceId);
                                }}
                                key={index}
                              >
                                {prices[priceId]?.name} <br />$
                                {prices[priceId].price}
                              </div>
                            );
                          },
                        )}
                      </div> */}
                    </label>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default UnitTileList;
