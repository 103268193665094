import React, { useEffect, useState } from "react";
import "./unitTile.css";

// tile that shows unit information
function UnitTile({ unit }) {
  return (
    <div className="unit-tile-content">
      <div className="unit-tile-image">
        <img src={unit.picture} alt={unit.name} />
      </div>
      <div className="unit-tile-text">
        <p className="unit-tile-name">{unit.name}</p>
        <p className="unit-tile-info">Engine: {unit.hp}hp</p>
        <p className="unit-tile-info">Max. Persons: {unit.capacity}</p>
      </div>
    </div>
  );
}

export default UnitTile;
